

export const homeObjOne = {
  id: 'team',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Team NFT Fractional Units',
  headline: 'More Info Coming Soon!',
  description:
    'Get access to exclusive content that allows all participants to receive perks and fan points. The future way to support a team and athletes.',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../images/HomeImg2.png'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Show your support',
  headline: 'Fans and Community can show support for there Favorite Team.',
  description:
    'We fractionalized team NFTs into units to allow anyone to participate in our Points system economy which will allow for fans to spend points on various NFT drops and merch. The more you participate, the more points you get.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/TeamTokens.png'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Tokenomics',
  headline: 'Some tokens are set aside for future parternships and CEX listings. More info coming soon!',
  description:
    "50% liquidity for uniswap, 10% marketing/CEX listing, 20% locked for future partnerships, and 20% for team",
  buttonLabel: 'Start Now',
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjFour = {
  id: 'rewards',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Fan Loyalty Points',
  headline: 'Loyalty Points can be Redeemed!',
  description:
    "Fans can redeem loyalty points for special NFT's, special jerseys or VIP tickets. Loyalty points can not be purchased, Only earned by engaging into the platform.",
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../images/NFT.jpg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjFive = {
  id: 'access',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Team Data On-Chain',
  headline: 'All team metadata is on-chain. ',
  description:
    'All history of names, logos, city, division records and Championships are also on-chain! Teams can be updated with new logo and name without migrating to new token. Team NFT is only allow to be update by owner of team. The rich history of the team can be tracked on-chain! Name and logo of team can be voted on by community and voting power is in the hands of fans who own team NFT token units!',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/teamNFT3.png'),
  // img: 'https://ipfs.io/ipfs/QmYuKXzi9NfsKtR7BUcc6G8iAsBALemxmJf3s9FJwVB4Q3', // Just set the URL directly
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

